import * as React from "react";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import {
  DataGridPremium,
  useGridApiRef,
  GridToolbar,
  useKeepGroupedColumnsHidden,
  GRID_TREE_DATA_GROUPING_FIELD,
  GRID_AGGREGATION_FUNCTIONS,
} from "@mui/x-data-grid-premium";

import useMediaQuery from '@mui/material/useMediaQuery';

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { MobileDateRangePicker } from "@mui/x-date-pickers-pro/MobileDateRangePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { addGroupIds } from "../../utile.js";

function setGrid(searchDate) {
  return <SetGrid searchDate={searchDate} />;
}

var apiRef

export default function StatByMenu(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const [selectedDate, setStartDate] = useState([
    dayjs(new Date()).add(-1, "month").add(1, "day"),
    dayjs(new Date()),
  ]);

  const [searchDate, setSearchDate] = useState([
    dayjs(new Date()).add(-1, "month").format("YYYYMMDD"),
    dayjs(new Date()).format("YYYYMMDD"),
  ]);

  return (
    <Box>
      <Stack
        direction="row"
        spacing={1}
        alignItems="flex-end"
        sx={{ paddingBottom: 1 }}
      >
        <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
          <DemoItem
            label="조회 일자 선택"
            component="DateRangePicker"
            sx={{ display: "flex" }}
          >
            <MobileDateRangePicker
              format="YYYY-MM-DD"
              defaultValue={[
                dayjs(new Date()).add(-1, "month").add(1, "day"),
                dayjs(new Date()),
              ]}
              disableMargin={false}
              isEndOfHighlightingOn={false}
              slotProps={{ textField: { size: "small" }, fieldSeparator: { sx: { display: "none" } }, }}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
            />
          </DemoItem>
        </LocalizationProvider>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setSearchDate([
              dayjs(selectedDate[0]).format("YYYYMMDD"),
              dayjs(selectedDate[1]).format("YYYYMMDD"),
            ]);
          }}
        >
          조회
        </Button>
      </Stack>
    <Box
      component={Paper}
      sx={matches? {
        maxWidth: "calc(100vw - 264px)",
        height: "calc(100vh - 210px)",
      }:{
        maxWidth: "calc(100vw - 32px)",
        height: "calc(100vh - 210px)",
      }}
      >
      {searchDate.length === 0 ? [] : setGrid(searchDate)}
    </Box>
    </Box>
  );
}

function SetGrid(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = React.useState(true);
  apiRef = useGridApiRef();
  
  useEffect(() => {
    setLoading(true)
    // fetch("http://localhost:8080/dashboard/org",  {
    fetch("https://gsr-dt-p001.azurewebsites.net/dashboard/org",  {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        startDate: props.searchDate[0],
        endDate: props.searchDate[1],
        sysSp: "P",
      }),
    })
      .then((response) => response.json())
      .then((data) => setData(data))
      .finally(() => setLoading(false))
      .catch((error) => console.error("Error fetching data:", error));
  }, [props.searchDate]);

  const columns = [
    { field: "team", headerName: "접속 인원", type: "number", width: 100 },
    { field: "teamAll", headerName: "인원 수", type: "number", width: 100 },
    {
      field: "tRatio",
      headerName: "접속 비율",
      type: "number",
      width: 100,
      valueGetter: (value, row) => {
        if (!row.team || !row.teamAll) {
          return 0;
        }
        return row.team / row.teamAll;
      },
      valueFormatter: (value) => {
        if (!value) {
          return null;
        }
        return `${Math.round(value * 1000) / 10}%`;
      },
    },
    { field: "ofc", headerName: "접속 인원", type: "number", width: 100},
    { field: "ofcAll", headerName: "인원 수", type: "number", width: 100},
    {
      field: "oRatio",
      headerName: "접속 비율",
      type: "number",
      width: 100,
      valueGetter: (value, row) => {
        if (!row.ofc || !row.ofcAll) {
          return 0;
        }
        return row.ofc / row.ofcAll;
      },
      valueFormatter: (value) => {
        if (!value) {
          return null;
        }
        return `${Math.round(value * 1000) / 10}%`;
      },
    },
    { field: "store", headerName: "접속 점포", type: "number", width: 100 },
    { field: "storeAll", headerName: "점포 수", type: "number", width: 100 },
    {
      field: "sRatio",
      headerName: "접속 비율",
      type: "number",
      width: 100,
      valueGetter: (value, row) => {
        if (!row.store || !row.storeAll) {
          return 0;
        }
        return row.store / row.storeAll;
      },
      valueFormatter: (value) => {
        if (!value) {
          return null;
        }
        return `${Math.round(value * 1000) / 10}%`;
      },
    },
  ];

  const tRatio = {
    label: 'tRatio',
    getCellValue: ({ row }) => ({ team: row.team, teamAll: row.teamAll }),
    apply: ({ values }) => {
      let team = 0;
      let teamAll = 0;
      values.forEach((value) => {
        if (value) {
          team += value.team;
          teamAll += value.teamAll;
        }
      });
      return (team / teamAll);
    },
    columnTypes: ['number'],
  };

  const oRatio = {
    label: 'oRatio',
    getCellValue: ({ row }) => ({ ofc: row.ofc, ofcAll: row.ofcAll }),
    apply: ({ values }) => {
      let ofc = 0;
      let ofcAll = 0;
      values.forEach((value) => {
        if (value) {
          ofc += value.ofc;
          ofcAll += value.ofcAll;
        }
      });
      return (ofc / ofcAll);
    },
    columnTypes: ['number'],
  };

  const sRatio = {
    label: 'sRatio',
    getCellValue: ({ row }) => ({ store: row.store, storeAll: row.storeAll }),
    apply: ({ values }) => {
      let store = 0;
      let storeAll = 0;
      values.forEach((value) => {
        if (value) {
          store += value.store;
          storeAll += value.storeAll;
        }
      });
      return (store / storeAll);
    },
    columnTypes: ['number'],
  };

  const rows = data.length === 0 ? [] : addGroupIds(data);
  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ["srv"],
      },
      pinnedColumns: { left: [GRID_TREE_DATA_GROUPING_FIELD] },
      aggregation: {
        model: {
          team: 'sum',
          teamAll: 'sum',
          tRatio: 'tRatio',
          ofc: 'sum',
          ofcAll: 'sum',
          oRatio: 'oRatio',
          store: 'sum',
          storeAll: 'sum',
          sRatio: 'sRatio',
        },
      },
    },
  });

  const columnGroupingModel = [
    {
      groupId: "영업팀장",
      children: [
        { field: "team" },
        { field: "teamAll" },
        { field: "tRatio" },
      ],
    },{
      groupId: "OFC",
      children: [
        { field: "ofc" },
        { field: "ofcAll" },
        { field: "oRatio" },
      ],
    },
    {
      groupId: "점포",
      children: [
        { field: "store" },
        { field: "storeAll" },
        { field: "sRatio" },
      ],
    },
  ]

  

  return (
    <DataGridPremium
      treeData
      getTreeDataPath={(row) => row.groupNm}
      groupingColDef={{
        headerName: "메뉴",
        width: 230,
        hideDescendantCount: true,
      }}
      defaultGroupingExpansionDepth={0}
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      columnGroupingModel={columnGroupingModel}
      loading={loading}

      initialState={initialState}
      density="compact"
      hideFooter={true}
      rowHeight={40}
      disableColumnFilter
      disableColumnMenu
      disableColumnReorder
      disableColumnResize
      disableColumnSelector
      disableDensitySelector

      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
      aggregationFunctions={{
        ...GRID_AGGREGATION_FUNCTIONS,
        sRatio,
        oRatio,
        tRatio,
      }}
    />
  );
}
