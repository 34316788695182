import * as React from "react";
import Divider from "@mui/material/Divider";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import Box from "@mui/material/Box";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Typography from "@mui/material/Typography";

import HomeIcon from "@mui/icons-material/Home";
import PeopleIcon from "@mui/icons-material/People";
import MapIcon from "@mui/icons-material/Map";
import CalendarIcon from "@mui/icons-material/CalendarMonth";
// import AccountTreeIcon from "@mui/icons-material/AccountTree";

const categories = [
  {
    id: "점포경영 PC/POS/GOT",
    children: [
      {
        id: "일별 접속현황",
        index: 4,
        icon: <PeopleIcon />,
        active: true,
      }, {
        id: "기간별 접속현황",
        index: 5,
        icon: <CalendarIcon />,
        active: false,
      },
    ],
  },{
    id: "모바일점포경영",
    children: [
      {
        id: "일별 접속현황",
        index: 1,
        icon: <PeopleIcon />,
        active: false,
      },
      {
        id: "기간별 접속현황",
        index: 2,
        icon: <CalendarIcon />,
        active: false,
      },
    ],
  },{
    id: "스토어맵",
    children: [
      {
        id: "GS더프레시 경쟁점 현황",
        index: 3,
        icon: <MapIcon />,
        active: false,
      },
    ],
  },
];

const item = {
  py: "2px",
  px: 3,
  color: "rgba(255, 255, 255, 0.7)",
  "&:hover, &:focus": {
    bgcolor: "rgba(255, 255, 255, 0.08)",
  },
};

const itemCategory = {
  boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
  py: 1.5,
  px: 3,
};


export default function Navigator(props) {
  const { ...other } = props;

  const [selectedIndex, setSelectedIndex] = React.useState(4);

  const handleListItemClick = (event, index) => {
    setSelectedIndex(index);
    props.updateindex(index);
  };

  return (
    <Drawer variant="permanent" {...other}>
      <Box
        sx={{
          flex: 1,
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
        }}
      >
        <List disablePadding>
          <ListItem sx={{ ...item, ...itemCategory }}>
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            DT Dashboard
          </ListItem>
          {categories.map(({ id, children }) => (
            <Box key={id} sx={{ bgcolor: "#081627" }}>
              <ListItem sx={{ py: 2, px: 3 }}>
                <ListItemText sx={{ color: "#fff" }}>{id}</ListItemText>
              </ListItem>
              {children.map(({ id: childId, icon, active, index }) => (
                <ListItem disablePadding key={childId}>
                  <ListItemButton selected={selectedIndex === index} sx={item} onClick={(event) => handleListItemClick(event, index)}>
                    <ListItemIcon>{icon}</ListItemIcon>
                    <ListItemText>{childId}</ListItemText>
                  </ListItemButton>
                </ListItem>
              ))}
              <Divider sx={{ mt: 2 }} />
            </Box>
          ))}
        </List>
        <Box component="footer" sx={{ paddingBottom: 2 }}>
          <Typography variant="body2" color="text.secondary" align="center">
            <img
              src="/gsrdt.png"
              alt="GS리테일 전략부문(P) DT기획팀"
              style={{ width: "170px" }}
            />
          </Typography>
        </Box>
      </Box>
    </Drawer>
  );
}
