import dayjs from "dayjs";
import "dayjs/locale/ko";

import { GridToolbarContainer, GridToolbarExport } from "@mui/x-data-grid-premium";

export function formatDate(dateStr) {
  const [year, month, day] = [
    dateStr.slice(0, 4),
    dateStr.slice(4, 6),
    dateStr.slice(6),
  ];
  const date = new Date(`${year}-${month}-${day}`);
  const formatter = new Intl.DateTimeFormat("ko-KR", {
    day: "numeric",
    month: "long",
  });

  var fd = formatter.format(date);
  return fd;
}

export function transformData(jsonData1, rowTitle) {
  const jsonData2 = [];
  let id = 1;

  const fields = Object.keys(jsonData1[0]).filter((key) => key !== "visitDate");

  fields.forEach((field, index) => {
    const entry = { id: id++, title: rowTitle[index], field: field };
    jsonData1.forEach((item) => {
      entry[item.visitDate] = item[field];
    });
    jsonData2.push(entry);
  });

  return jsonData2;
}

export function transformGroupData(jsonD1) {
  const result = [];
  const mapping = {};

  jsonD1.forEach((item, index) => {
    if (mapping[item.groupId]) {
      mapping[item.groupId][item.visitDate] = item.cnt;
    } else {
      const newItem = {
        id: item.groupId,
        scr: item.groupNm.split("^"),
      };
      newItem[item.visitDate] = item.cnt;

      mapping[item.groupId] = newItem;
      result.push(newItem);
    }
  });

  return result;
}

export function addGroupIds(jsonD1) {
  const result = [];
  const mapping = {};

  jsonD1.forEach((item, index) => {
    if (mapping[item.groupId]) {
      mapping[item.groupId] = item.cnt;
    } else {
      const newItem = {
        ...item,
        id: item.groupId,
        groupNm: item.groupNm.split("^"),
      };

      mapping[item.groupId] = newItem;
      result.push(newItem);
    }
  });

  return result;
}

export function addIds(jsonD1) {
  const result = [];
  const mapping = {};

  jsonD1.forEach((item, index) => {
    if (mapping[item.groupId]) {
      mapping[item.groupId] = item.cnt;
    } else {
      const newItem = {
        ...item,
        id: item.groupId,
      };

      mapping[item.groupId] = newItem;
      result.push(newItem);
    }
  });

  return result;
}

export function setAggColList(col) {
  const aggList = {};

  for (const item of col) {
    const field = parseInt(item.field);
    aggList[field] = "sum";
  }

  return aggList;
}

export function setAggreationList(startDate, endDate) {
  const start = dayjs(startDate, "YYYYMMDD")
  const end = dayjs(endDate, "YYYYMMDD")

  const dateRangeObject = {};

  for (let i = 0; i <= end.diff(start, 'days'); i++) {
      let dateString = start.add(i, 'day').format('YYYYMMDD');
      dateRangeObject[dateString] = 'sum';
  }
  return dateRangeObject;
}

export function exportToolbar() {
  return (
    <GridToolbarContainer>
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}