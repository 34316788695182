import * as React from "react";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { Alert, Box } from "@mui/material";
import { DataGridPremium, useGridApiRef, GridToolbar } from "@mui/x-data-grid-premium";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { addIds } from "../../utile.js";

var globalApiRef

export default function StatBySp(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [baseDate, setBaseDate] = useState();

  return (
    <Box>
      <Alert severity="info" sx={{ marginBottom: 1 }}>
        GS더프레시 기준, 반경 1km 이내 수퍼/마트 현황 (기준일자: {baseDate})
      </Alert>
      <Box
        component={Paper}
        sx={matches? {
          maxWidth: "calc(100vw - 264px)",
          height: "calc(100vh - 142px)",
        }:{
          maxWidth: "calc(100vw - 32px)",
          height: "calc(100vh - 142px)",
        }}
        >
        <SetGrid setBaseDate={setBaseDate}/>
      </Box>
    </Box>
  );
}

function SetGrid(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = React.useState(true);
  globalApiRef = useGridApiRef();

  useEffect(() => {
    setLoading(true)
    // fetch("http://localhost:8080/storemap/c2001", {
    fetch("https://gsr-dt-p001.azurewebsites.net/storemap/c2001", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((data) => setData(data))
      .finally(() => {
        setLoading(false);
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, []);

  const columns = [
    { field: "basePlaceName", headerName: "GS더프레시 점포명", type: "string", width: 200 },
    { field: "expPlaceClassName", headerName: "수퍼/마트 분류", type: "string", width: 200 },
    { field: "expPlaceName", headerName: "수퍼/마트 점포명", type: "string", width: 250 },
    { field: "distance", headerName: "점포간 거리(단위: m)", type: "number", width: 150 },
  ]

  const rows = data.length === 0 ? [] : addIds(data);

  if(data.length > 0) props.setBaseDate(data[0].updated)

  return (
    <DataGridPremium
      apiRef={globalApiRef}
      rows={rows}
      columns={columns}
      loading={loading}
      
      initialState={{
        pinnedColumns: { left: ["basePlaceName"] },
      }}
      
      density="compact"
      rowHeight={40}

      disableColumnFilter
      disableColumnMenu
      disableColumnReorder
      disableColumnResize
      disableColumnSelector
      disableDensitySelector

      slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
    />
  );
}
