import * as React from "react";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import {
  DataGridPremium,
  useGridApiRef,
  GridToolbar,
  useKeepGroupedColumnsHidden,
  GRID_TREE_DATA_GROUPING_FIELD,
} from "@mui/x-data-grid-premium";

import useMediaQuery from '@mui/material/useMediaQuery';

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { MobileDateRangePicker } from "@mui/x-date-pickers-pro/MobileDateRangePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Unstable_Grid2';
import { formatDate, transformGroupData, setAggreationList } from "../../utile.js";

var apiRef

export default function StatByMenu(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [expanded, setExpanded] = useState(-1);

  const [selectedDate, setStartDate] = useState([
    dayjs(new Date()).add(-1, "month").add(1, "day"),
    dayjs(new Date()),
  ]);

  const [searchDate, setSearchDate] = useState([
    dayjs(new Date()).add(-1, "month").format("YYYYMMDD"),
    dayjs(new Date()).format("YYYYMMDD"),
  ]);

  return (
    <Box>
      <Grid
        xs={12}
        container
        justifyContent="space-between"
        flexDirection={{ xs: "row", sm: "row" }}
        sx={{ paddingBottom: 1 }}
      >
        <Grid container alignItems="flex-end" spacing={1}>
          <Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
              <DemoItem
                label="조회 일자 선택"
                component="DateRangePicker"
                sx={{ display: "flex" }}
              >
                <MobileDateRangePicker
                  format="YYYY-MM-DD"
                  defaultValue={[
                    dayjs(new Date()).add(-1, "month").add(1, "day"),
                    dayjs(new Date()),
                  ]}
                  disableMargin={false}
                  isEndOfHighlightingOn={false}
                  slotProps={{
                    textField: { size: "small" },
                    fieldSeparator: { sx: { display: "none" } },
                  }}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                  // sx={{ fontSize: "8px",}}
                />
              </DemoItem>
            </LocalizationProvider>
          </Grid>
          <Grid>
            <Button
              variant="contained"
              color="primary"
              // sx={{ height: "31px", borderRadius: '3px'}}
              onClick={() => {
                setSearchDate([
                  dayjs(selectedDate[0]).format("YYYYMMDD"),
                  dayjs(selectedDate[1]).format("YYYYMMDD"),
                ]);
              }}
            >
              조회
            </Button>
          </Grid>
        </Grid>
        <Grid container alignItems="flex-end" spacing={1}>
          <Grid>
            <Button variant="outlined" sx={{ fontWeight: 900 }} onClick={() => {setExpanded(-1)}}>
              +
            </Button>
          </Grid>
          <Grid>
            <Button variant="outlined" sx={{ fontWeight: 900 }} onClick={() => {setExpanded(0)}}>
              -
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Box
        component={Paper}
        sx={
          matches
            ? {
                maxWidth: "calc(100vw - 264px)",
                height: "calc(100vh - 210px)",
              }
            : {
                maxWidth: "calc(100vw - 32px)",
                height: "calc(100vh - 210px)",
              }
        }
      >
      <SetGrid searchDate={searchDate} expanded={expanded} />
      </Box>
    </Box>
  );
}

function SetGrid(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = React.useState(true);
  
  apiRef = useGridApiRef();
  
  useEffect(() => {
    setLoading(true)
    // fetch("http://localhost:8080/dashboard/menu/daily",  {  
    fetch("https://gsr-dt-p001.azurewebsites.net/dashboard/menu/daily",  {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        startDate: props.searchDate[0],
        endDate: props.searchDate[1],
        sysSp: "M",
      }),
    })
      .then((response) => response.json())
      .then((data) => setData(data))
      .finally(() => {
        setLoading(false);
        apiRef.current.setAggregationModel(setAggreationList(props.searchDate[0], props.searchDate[1]));
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [props.searchDate]);

  const uniqueDates = new Set();
  const dateCol = data
    .filter((obj) => {
      const date = obj.visitDate;
      if (!uniqueDates.has(date)) {
        uniqueDates.add(date);
        return true;
      }
      return false;
    })
    .map((obj) => {
      const newObj = {};
      newObj.field = obj.visitDate;
      newObj.headerName = formatDate(obj.visitDate);
      newObj.width = 90;
      newObj.type = "number";
      return newObj;
    });

  const columns = [...dateCol];
  const rows = data.length === 0 ? [] : transformGroupData(data);
  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ["srv"],
      },
      pinnedColumns: { left: [GRID_TREE_DATA_GROUPING_FIELD] },
    },
  });

  return (
    <DataGridPremium
      treeData
      getTreeDataPath={(row) => row.scr}
      groupingColDef={{ headerName: "메뉴", width: 200, hideDescendantCount: true }}
      // defaultGroupingExpansionDepth={1}
      isGroupExpandedByDefault={() => props.expanded}
      
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      loading={loading}

      initialState={initialState}

      density="compact"
      hideFooter={true}
      rowHeight={40}

      disableColumnFilter
      disableColumnMenu
      disableColumnReorder
      disableColumnResize
      disableColumnSelector
      disableDensitySelector

      slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
    />
  );
}
