import * as React from "react";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { Alert, Box } from "@mui/material";
import { useTheme } from '@mui/material/styles';
import {
  DataGridPremium,
  useGridApiRef,
  GridToolbar,
  useKeepGroupedColumnsHidden,
  GRID_TREE_DATA_GROUPING_FIELD,
} from "@mui/x-data-grid-premium";

import useMediaQuery from '@mui/material/useMediaQuery';

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { MobileDateRangePicker } from "@mui/x-date-pickers-pro/MobileDateRangePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Unstable_Grid2';
import { addGroupIds } from "../../utile.js";

function setGrid(searchDate, expanded) {
  return <SetGrid searchDate={searchDate} expanded={expanded}/>;
}

var apiRef

export default function StatByMenu(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));
  const [expanded, setExpanded] = useState(-1);

  const [selectedDate, setStartDate] = useState([
    dayjs(new Date()).add(-1, "month").add(1, "day"),
    dayjs(new Date()),
  ]);

  const [searchDate, setSearchDate] = useState([
    dayjs(new Date()).add(-1, "month").format("YYYYMMDD"),
    dayjs(new Date()).format("YYYYMMDD"),
  ]);

  return (
    <Box>
      <Grid
        xs={12}
        container
        justifyContent="space-between"
        flexDirection={{ xs: "row", sm: "row" }}
        sx={{ paddingBottom: 1 }}
      >
        <Grid container alignItems="flex-end" spacing={1}>
          <Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
          <DemoItem
            label="조회 일자 선택"
            component="DateRangePicker"
            sx={{ display: "flex" }}
          >
            <MobileDateRangePicker
              format="YYYY-MM-DD"
              defaultValue={[
                dayjs(new Date()).add(-1, "month").add(1, "day"),
                dayjs(new Date()),
              ]}
              disableMargin={false}
              isEndOfHighlightingOn={false}
              slotProps={{
                textField: { size: "small" },
                fieldSeparator: { sx: { display: "none" } },
              }}
              onChange={(newValue) => {
                setStartDate(newValue);
              }}
            />
          </DemoItem>
        </LocalizationProvider>
          </Grid>
          <Grid>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            setSearchDate([
              dayjs(selectedDate[0]).format("YYYYMMDD"),
              dayjs(selectedDate[1]).format("YYYYMMDD"),
            ]);
          }}
        >
          조회
        </Button>
        
        </Grid>
        </Grid>
        <Grid container alignItems="flex-end" spacing={1}>
          <Grid>
            <Button
              variant="outlined"
              sx={{ fontWeight: 900 }}
              onClick={() => {
                setExpanded(-1);
              }}
            >
              +
            </Button>
          </Grid>
          <Grid>
            <Button
              variant="outlined"
              sx={{ fontWeight: 900 }}
              onClick={() => {
                setExpanded(0);
              }}
            >
              -
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Alert severity="info" color="error" sx={{marginBottom: 1}}>
      조회 시점에 따라 20~30초 정도 소요될 수 있습니다.
      </Alert>
    <Box
      component={Paper}
      sx={matches? {
        maxWidth: "calc(100vw - 264px)",
        height: "calc(100vh - 266px)",
      }:{
        maxWidth: "calc(100vw - 32px)",
        height: "calc(100vh - 266px)",
      }}
      >
      {searchDate.length === 0 ? [] : setGrid(searchDate, expanded)}
    </Box>
    </Box>
  );
}

function SetGrid(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = React.useState(true);
  apiRef = useGridApiRef();
  
  useEffect(() => {
    setLoading(true)
    // fetch("http://localhost:8080/dashboard/menu",  {
    fetch("https://gsr-dt-p001.azurewebsites.net/dashboard/menu",  {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        startDate: props.searchDate[0],
        endDate: props.searchDate[1],
        sysSp: "P",
      }),
    })
      .then((response) => response.json())
      .then((data) => setData(data))
      .finally(() => setLoading(false))
      .catch((error) => console.error("Error fetching data:", error));
  }, [props.searchDate]);

  const columns = [
    // { field: "menuNm", headerName: "메뉴명", type: "string", width: 109},
    // { field: "scrNm", headerName: "화면명", type: "string", width: 150},
    { field: "team", headerName: "영업팀장", type: "number", width: 85},
    { field: "ofc", headerName: "OFC", type: "number", width: 85},
    { field: "store", headerName: "경영주", type: "number", width: 85},
    { field: "head", headerName: "본부사용자", type: "number", width: 85},
    { field: "etc", headerName: "기타", type: "number", width: 85},
  ];

  const rows = data.length === 0 ? [] : addGroupIds(data);
  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      pinnedColumns: { left: [GRID_TREE_DATA_GROUPING_FIELD] },
      aggregation: {
        model: {
          team: 'sum',
          ofc: 'sum',
          store: 'sum',
          head: 'sum',
          etc: 'sum',
        },
      },
    },
  });

  return (
    <DataGridPremium
      treeData
      getTreeDataPath={(row) => row.groupNm}
      groupingColDef={{
        headerName: "메뉴",
        width: 280,
        hideDescendantCount: true,
      }}
      isGroupExpandedByDefault={() => props.expanded}
      defaultGroupingExpansionDepth={-1}
      apiRef={apiRef}
      rows={rows}
      columns={columns}
      loading={loading}

      initialState={initialState}
      density="compact"
      hideFooter={true}
      rowHeight={40}
      disableColumnFilter
      disableColumnMenu
      disableColumnReorder
      disableColumnResize
      disableColumnSelector
      disableDensitySelector

      slots={{ toolbar: GridToolbar }}
      slotProps={{
        toolbar: {
          showQuickFilter: true,
        },
      }}
    />
  );
}
