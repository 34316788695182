import React, { useState } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Grid from "@mui/material/Grid";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Toolbar from "@mui/material/Toolbar";
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import PCOrgStat from "./PCOrgStat";
import PCMenuStat from "./PCMenuStat";

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

function Header(props) {
  const { onDrawerToggle } = props;
  const [selectedTabVal, setTabIndex] = useState(0);

  const handleChange = (event, selectedTab) => {
    setTabIndex(selectedTab);
  };

  const DAUGrid = [
    <PCMenuStat />,
    <PCOrgStat />,
  ];

  return (
    <React.Fragment>
      <AppBar
        component="div"
        color="primary"
        position="sticky"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Toolbar>
          <Grid container alignItems="center" spacing={1}>
            <Grid sx={{ display: { sm: 'none', xs: 'block' } }} item>
              <IconButton
                color="inherit"
                aria-label="open drawer"
                onClick={onDrawerToggle}
                edge="start"
              >
                <MenuIcon />
              </IconButton>
            </Grid>
            <Grid item xs>
              <Typography color="inherit" variant="h6" component="h2">
              기간별 접속현황
              </Typography>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>
      <AppBar
        component="div"
        position="sticky"
        elevation={0}
        sx={{ zIndex: 0 }}
      >
        <Tabs value={selectedTabVal} onChange={handleChange} textColor="inherit" indicatorColor="">
          <Tab label="메뉴별" {...a11yProps(0)} />
          <Tab label="조직별" {...a11yProps(1)} />
        </Tabs>
      </AppBar>
      <Box component="main" sx={{ flex: 1, py: 2, px: 2, bgcolor: "white" }}>
        {selectedTabVal === 0 ? DAUGrid[0] : DAUGrid[1]}
      </Box>
    </React.Fragment>
  );
}

Header.propTypes = {
  onDrawerToggle: PropTypes.func.isRequired,
};

export default Header;
