import * as React from 'react';
import { LicenseInfo } from '@mui/x-license-pro';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Navigator from './Navigator';
import DAU from './page/DAU/DAU';
import MAU from './page/MAU/MAU';
import StoreMap from './page/StoreMap/StoreMap';
import PCDAU from './page/PCDAU/PCDAU';
import PCMAU from './page/PCMAU/PCMAU';

import { useState } from 'react';
import "@fontsource/noto-sans-kr/400.css";
import "@fontsource/noto-sans-kr/600.css";
import "@fontsource/noto-sans-kr/800.css";

LicenseInfo.setLicenseKey('09a113ecdba820f0fec7ea28633adbf3Tz04NjkzNCxFPTE3NDI2NDczNDQwMDAsUz1wcmVtaXVtLExNPXN1YnNjcmlwdGlvbixLVj0y');

let theme = createTheme({
  palette: {
    primary: {
      light: "#63ccff",
      main: "#006db3",
      dark: "#022F60",
    },
  },
  typography: {
    fontFamily: "Noto Sans KR",
    h5: {
      fontWeight: 600,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  components: {
    MuiTab: {
      defaultProps: {
        disableRipple: true,
      },
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  components: {
    MuiMultiInputDateRangeField: {
      styleOverrides: {
        root: {
          ">:not(style)~:not(style)": {
            marginLeft: "5px",
          },
        },
      },
    },
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: "#022F60",
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          fontWeight: "bold",
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          // padding: theme.spacing(1),
          paddingLeft: "0px",
          paddingRight: theme.spacing(1)
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          borderRadius: 4,
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: "rgb(255,255,255,0.15)",
        },
      },
    },
    MuiListItemButton: {
      styleOverrides: {
        root: {
          "&.Mui-selected": {
            color: "#4fc3f7",
          },
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: 14,
          fontWeight: theme.typography.fontWeightMedium,
        },
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          color: "inherit",
          minWidth: "auto",
          marginRight: theme.spacing(2),
          "& svg": {
            fontSize: 20,
          },
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          width: 32,
          height: 32,
        },
      },
    },

    // Header Tab Style
    MuiAppBar:{
      styleOverrides:{
        root:{
          backgroundColor: "#FFF",
          color: "#022F60",
          fontWeight: 800,
        },
      },
    },
    MuiTypography:{
      styleOverrides:{
        root:{
          fontWeight: 600,
        },
      },
    },
    MuiTabs:{
      styleOverrides:{
        root: {
          marginLeft: theme.spacing(1),
          paddingTop: '20px',
          borderBottom: '2px solid #006DB3',
        },
        indicator: {
          display: "none",
        }
      }
    },
    MuiTab:{
      styleOverrides:{
        root:{
          textTransform: "none",
          margin: "12 8px",
          minWidth: 0,
          padding: '0px 12px',
          // [theme.breakpoints.up("md")]: {
          //   padding: 0,
          //   minWidth: 0,
          // },
          backgroundColor: "#022F60",
          color: "#FFF",
          borderTopLeftRadius: '8px',
          borderTopRightRadius: '8px',
          lineHeight: 1,
          minHeight: 30,
          fontSize: 14,
        },
        flexContainer: {
          paddingTop: '20px',
        }
      },
    },

    // DateGrid Style
    MuiDataGrid: {
      styleOverrides: {
        root: {
          '.MuiDataGrid-container--top [role=row]': {backgroundColor: '#F5F6FA'},
          '.MuiDataGrid-cell': {paddingTop: '0px'},
          '.MuiDataGrid-treeDataGroupingCellToggle': {marginRight: '0px'},
          '.MuiDataGrid-aggregationColumnHeaderLabel': {display: 'none'},
        },
        footerCell: {
          fontWeight: 900,
          color: "#022F60",
        },
        cell: { 
          paddingTop: "0px",
          fontSize: 14,
        },
        pinnedColumnHeaders: {
          backgroundColor: "#F5F6FA",
          color: "#000",
          minHeight: "40px",
          maxHeight: "40px",
        },
        columnHeaderRow: {
          backgroundColor: "#F5F6FA",
          color: "#000",
          fontSize: 14,
          minHeight: "40px",
        },
        columnHeaders: {
          backgroundColor: "#F5F6FA",
          color: "#000",
          fontSize: 14,
          minHeight: "40px",
        },
        columnHeader: {
          backgroundColor: "#F5F6FA",
          color: "#000",
          fontSize: 14,
          minHeight: "40px",
        },
        aggregationColumnHeaderLabel: {color: "#F5F6FA"},
        columnHeaderDraggableContainer: {flowDirection: "row"},
        columnHeaderTitleContainer: {flowDirection: "row"},
        virtualScrollerContent: { fontSize: 14 },
        columnHeaderTitle: { fontWeight: 600},
      },
    },

    // DateRange Picker Style
    MuiInputBase: {
      styleOverrides: { 
        root : {
          fontSize: 14,
        },
        inputSizeSmall: {
          fontSize: 14,
        },
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          width: "100px",
          borderRadius: '3px',
        },
        input: {
          borderRadius: '3px',
          fontSize: 14,
          padding: "6px 14px",
        },
      },
    },

    // Button Style
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          borderRadius: '3px',
          height: '32px',
          fontSize: 14,
          minWidth: 0,
        },
        contained: {
          borderRadius: '3px',
          height: '32px',
          boxShadow: "none",
          fontSize: 14,
          "&:active": {
            boxShadow: "none",
          },
        },
      },
    },
  },
};

const drawerWidth = 230;

export default function Dashboard() {
  const [mobileOpen, setMobileOpen] = useState(false);
  const isSmUp = useMediaQuery(theme.breakpoints.up('sm'));
  const [index, updatePageIndex] = useState(4);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: 'flex', minHeight: '100vh' }}>
        <CssBaseline />
        <Box
          component="nav"
          sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        >
          {isSmUp ? null : (
            <Navigator
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
              updateindex={updatePageIndex}
            />
          )}

          <Navigator
            PaperProps={{ style: { width: drawerWidth } }}
            sx={{ display: { sm: 'block', xs: 'none' } }}
            updateindex={updatePageIndex}
          />
        </Box>
        <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
          {index === 1 && <DAU onDrawerToggle={handleDrawerToggle} />}
          {index === 2 && <MAU onDrawerToggle={handleDrawerToggle} />}
          {index === 3 && <StoreMap onDrawerToggle={handleDrawerToggle} />}
          {index === 4 && <PCDAU onDrawerToggle={handleDrawerToggle} />}
          {index === 5 && <PCMAU onDrawerToggle={handleDrawerToggle} />}
        </Box>
      </Box>
    </ThemeProvider>
  );
}