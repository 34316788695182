import * as React from "react";
import Paper from "@mui/material/Paper";
import { useEffect, useState } from "react";
import { Box } from "@mui/material";
import { DataGridPremium, useGridApiRef, GridToolbar } from "@mui/x-data-grid-premium";

import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoItem } from "@mui/x-date-pickers/internals/demo";
import { MobileDateRangePicker } from "@mui/x-date-pickers-pro/MobileDateRangePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import "dayjs/locale/ko";
import Button from "@mui/material/Button";
import Grid from '@mui/material/Unstable_Grid2';
import { formatDate, transformData, setAggreationList } from "../../utile.js";

var globalApiRef

export default function StatBySp(props) {
  const theme = useTheme();
  const matches = useMediaQuery(theme.breakpoints.up('sm'));

  const [selectedDate, setStartDate] = useState([
    dayjs(new Date()).add(-1, "month").add(1, "day"),
    dayjs(new Date()),
  ]);

  const [searchDate, setSearchDate] = useState([
    dayjs(new Date()).add(-1, "month").format("YYYYMMDD"),
    dayjs(new Date()).format("YYYYMMDD"),
  ]);

  return (
    <Box>
      <Grid
        xs={12}
        container
        justifyContent="space-between"
        flexDirection={{ xs: "column", sm: "row" }}
        sx={{ paddingBottom: 1 }}
      >
        <Grid container alignItems="flex-end" spacing={1}>
          <Grid>
            <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
              <DemoItem
                label="조회 일자 선택"
                component="DateRangePicker"
                sx={{ display: "flex" }}
              >
                <MobileDateRangePicker
                  format="YYYY-MM-DD"
                  defaultValue={[
                    dayjs(new Date()).add(-1, "month").add(1, "day"),
                    dayjs(new Date()),
                  ]}
                  disableMargin={false}
                  isEndOfHighlightingOn={false}
                  slotProps={{
                    textField: { size: "small" },
                    fieldSeparator: { sx: { display: "none" } },
                  }}
                  onChange={(newValue) => {
                    setStartDate(newValue);
                  }}
                />
              </DemoItem>
            </LocalizationProvider>
          </Grid>
          <Grid>
            <Button
              variant="contained"
              color="primary"
              onClick={() => {
                setSearchDate([
                  dayjs(selectedDate[0]).format("YYYYMMDD"),
                  dayjs(selectedDate[1]).format("YYYYMMDD"),
                ]);
              }}
            >
              조회
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <Box
        component={Paper}
        sx={
          matches
            ? {
                maxWidth: "calc(100vw - 264px)",
                height: "calc(100vh - 210px)",
              }
            : {
                maxWidth: "calc(100vw - 32px)",
                height: "calc(100vh - 210px)",
              }
        }
      >
        <SetGrid searchDate={searchDate} />
      </Box>
    </Box>
  );
}

function SetGrid(props) {
  const [data, setData] = useState([]);
  const [loading, setLoading] = React.useState(true);
  globalApiRef = useGridApiRef();

  useEffect(() => {
    setLoading(true)
    // fetch("http://localhost:8080/dashboard/sp/daily", {
    fetch("https://gsr-dt-p001.azurewebsites.net/dashboard/sp/daily", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        startDate: props.searchDate[0],
        endDate: props.searchDate[1],
        sysSp: "p",
      }),
    })
      .then((response) => response.json())
      .then((data) => setData(data))
      .finally(() => {
        setLoading(false);
        globalApiRef.current.setAggregationModel(setAggreationList(props.searchDate[0], props.searchDate[1]));
      })
      .catch((error) => console.error("Error fetching data:", error));
  }, [props.searchDate]);

  const dateCol = data.map((obj) => {
    const newObj = {};
    newObj.field = obj.visitDate;
    newObj.headerName = formatDate(obj.visitDate);
    newObj.width = 90;
    newObj.type = "number";
    return newObj;
  });

  const colTitle = {
    field: "title",
    headerName: "계정구분",
    width: 130,
    sx: { fontWeight: "bold" },
  };
  const columns = [colTitle, ...dateCol];
  const rowTitle = [
    "경영주",
    "조력자",
    "통합ID",
    "영업팀장",
    "OFC",
    "스토어매니저",
    "본부사용자+기타",
  ];

  const rows = data.length === 0 ? [] : transformData(data, rowTitle);
  return (
    <DataGridPremium
      apiRef={globalApiRef}
      rows={rows}
      columns={columns}
      loading={loading}
      
      initialState={{
        pinnedColumns: { left: ["title"] },
      }}
      
      density="compact"
      hideFooter={true}
      rowHeight={40}

      disableColumnFilter
      disableColumnMenu
      disableColumnReorder
      disableColumnResize
      disableColumnSelector
      disableDensitySelector

      slots={{ toolbar: GridToolbar }}
        slotProps={{
          toolbar: {
            showQuickFilter: true,
          },
        }}
    />
  );
}
